import React from "react"
import Layout from "@/components/layout"
import HeaderOne from "@/components/header-one"
import PageBanner from "@/components/page-banner"
import Footer from "@/components/footer-one"
import TermsCondition from "@/components/terms"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import { Container } from "react-bootstrap"

const TermsPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="TCs">
          <HeaderOne />
          <PageBanner title="terms & Conditions" name="Privacy" />
          <section className="commonSection blogDetails">
            <Container>
              <div className="terms">
                <TermsCondition />
              </div>
            </Container>
          </section>
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default TermsPage
